@tailwind base;
@tailwind components;
@tailwind utilities;

.app-toolbar-logo::before {
  content: "";
  background: url("data:image/svg+xml;utf8,<svg width='130' height='70' viewBox='0 0 130 70' xmlns='http://www.w3.org/2000/svg'><path d='M0.5 0H38.6797L55.9864 32.9737L55.2499 0H106.433L130 70H90.2314L78.4482 32.0526L78.8164 70H47.5171L23.5824 33.5263L35.918 70H0.5V0Z' fill='%23000000' /></svg>") no-repeat right center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  height: 60px;
  width: 111px;
  position: absolute;
  top: 0;
  right: -103px;
}

.custom-block-shadow {
  box-shadow: -8px 8px 0 black;
  -webkit-box-shadow: -8px 8px 0 black;
  -moz-box-shadow: -8px 8px 0 black;
  -o-box-shadow: -8px 8px 0 black;
}

:root {
  --swiper-theme-color: #FFF;
}
